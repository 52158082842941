const menu = {
    profile: 'プロフィール',
    language: '言語',
    appearance: {
        label: '外観',
        light: 'ライトモード',
        dark: 'ダークモード',
        system: 'システムと同期',
    },
    sign_out: 'ログアウト',
};
export default Object.freeze(menu);
