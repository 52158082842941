const demo_app = {
    title: 'ライブプレビューへのサインインに成功しました！',
    subtitle: 'ここにユーザー情報があります：',
    username: 'ユーザー名：',
    user_id: 'ユーザーID：',
    sign_out: 'ライブプレビューからサインアウトする',
    continue_explore: 'または続けて探索する',
    customize_sign_in_experience: 'サインイン体験のカスタマイズ',
    enable_passwordless: 'パスワードレスを有効にする',
    add_social_connector: 'ソーシャルコネクターを追加する',
};
export default Object.freeze(demo_app);
