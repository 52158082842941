const dashboard = {
    page_title: 'ダッシュボード',
    title: 'ダッシュボード',
    description: 'アプリのパフォーマンスについての概要を取得',
    total_users: '合計ユーザー数',
    total_users_tip: '合計ユーザー数',
    new_users_today: '本日の新規ユーザー',
    new_users_today_tip: 'アプリに本日登録された新規ユーザーの数',
    new_users_7_days: '過去7日間の新規ユーザー',
    new_users_7_days_tip: 'アプリに過去7日間に登録された新規ユーザーの数',
    daily_active_users: '1日あたりのアクティブユーザー',
    daily_active_users_tip: 'アプリでトークンを交換したユニークユーザーの数',
    weekly_active_users: '週間アクティブユーザー',
    weekly_active_users_tip: 'アプリで過去7日間にトークンを交換したユニークユーザーの数',
    monthly_active_users: '月間アクティブユーザー',
    monthly_active_users_tip: 'アプリで過去30日間にトークンを交換したユニークユーザーの数',
};
export default Object.freeze(dashboard);
