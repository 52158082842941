const logs = {
    page_title: '監査ログ',
    title: '監査ログ',
    subtitle: 'ユーザーによって行われた認証イベントのログデータを表示します',
    event: 'イベント',
    user: 'ユーザー',
    application: 'アプリケーション',
    time: '時間',
    filter_by: 'フィルター',
};
export default Object.freeze(logs);
