const invitation = {
    find_your_tenants: 'テナントを見つける',
    find_tenants_description: 'あなたのメールアドレスはすでに複数のテナントに登録されているかもしれません。既存のものに参加するか、新しいものを作成し続けるか選択できます。',
    create_new_tenant: '新しいテナントを作成',
    email_not_match_title: '現在サインインされているメールアドレス\n{{email}}',
    email_not_match_description: '招待を受け入れ、組織のメンバーになるには、正しいアカウントでサインインしてください。',
    switch_account: '別のアカウントにサインイン',
    invalid_invitation_status: '招待状が無効です。管理者に問い合わせて、もう一度お試しください。',
    invitation_not_found: '招待が見つかりませんでした。管理者に連絡してください。',
};
export default Object.freeze(invitation);
